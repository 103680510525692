import md5 from 'md5';
import {PublicKey} from "@solana/web3.js";
const getLocalStorage = (key) => JSON.parse(window.localStorage.getItem(key));
const setLocalStorage = (key, value) => window.localStorage.setItem(key, JSON.stringify(value));
const removeLocalStorage = (key, value) => window.localStorage.removeItem(key);
const SERVER_URL = import.meta.env.VITE_SERVER_URL || 'http://localhost:3030'
// const SERVER_URL = 'http://localhost:3030'

const myFetch = async (path, method = 'GET', token, body) => {
    try {
        const headers = {
            'Content-Type': 'application/json; charset=UTF-8',
        }
        if (token){
            headers['Authorization'] = `Bearer ${token}`
        }
        const bodyData = body ? JSON.stringify(body) : null;
        // const response = await fetch(`${SERVER_URL}${path}`, {
        const response = await fetch(`${SERVER_URL}${path}`, {
            method,
            headers,
            body: bodyData,
        });
        const data = await response.json();
        if (data.error){
            throw data.error;
        }
        return data;
    }catch (e) {
        throw e;
    }
}

function generateHash(inputString) {
    return md5(inputString);
}
function formatMilliseconds(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000);

    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `${minutes}:${formattedSeconds}`;
}
function isValidSolanaAddress(addressString){
    try {
        new PublicKey(addressString);
        return true;
    } catch (error) {
        // If an error is thrown, it's not a valid address
        return false;
    }
}


export { getLocalStorage, setLocalStorage, removeLocalStorage, myFetch, generateHash, formatMilliseconds, isValidSolanaAddress };
