import { useState, useRef } from "react";
import {useFrame, useLoader} from "@react-three/fiber";
import { Float } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";
import * as THREE from "three";
import useGame from "../../stores/useGame.js";
import EndModel from "./EndModel.jsx";
import {MeshStandardMaterial, RepeatWrapping, TextureLoader} from "three";
const boxGeometry = new THREE.BoxGeometry(1, 1, 1);

export const blockDimensions = {
  width: 4.2,
  height: 0.3,
  length: 4,
};

export function BlockEmpty({ position = [0, 0, 0] }) {
    const floorTexture = useLoader(TextureLoader, "./textures/actual-new/floor.jpg");
    floorTexture.wrapS = THREE.RepeatWrapping;
    floorTexture.wrapT = THREE.RepeatWrapping;
    floorTexture.repeat.set(1, 1);

    const texturedMaterialFloor = new THREE.MeshStandardMaterial({
        map: floorTexture,
    });
  return (
    <group position={position}>
      <mesh
        geometry={boxGeometry}
        material={texturedMaterialFloor}
        position={[0, -0.2, 0]}
        scale={[
          blockDimensions.width,
          blockDimensions.height,
          blockDimensions.length,
        ]}
        receiveShadow
      />
    </group>
  );
}

export function BlockSpinner({ position = [0, 0, 0], difficulty }) {
    const floorTexture = useLoader(TextureLoader, "./textures/actual-new/floor.jpg");
    const blockSpinnerTexture = useLoader(TextureLoader, "./textures/actual-new/block-side-01.jpg"); // left
    const blockSpinnerTexture2 = useLoader(TextureLoader, "./textures/actual-new/block-side-01.jpg"); // right
    const blockSpinnerTexture3 = useLoader(TextureLoader, "./textures/actual-new/block-01.jpg"); // top
    const blockSpinnerTexture4 = useLoader(TextureLoader, "./textures/actual-new/block-01.jpg"); // floor
    const blockSpinnerTexture5 = useLoader(TextureLoader, "./textures/actual-new/block-01.jpg"); // front
    const blockSpinnerTexture6 = useLoader(TextureLoader, "./textures/actual-new/block-01.jpg"); // back
    floorTexture.wrapS = RepeatWrapping;
    floorTexture.wrapT = RepeatWrapping;
    floorTexture.repeat.set(1, 1);

    const texturedMaterialFloor = new MeshStandardMaterial({
        map: floorTexture,
    });

    const obstacleMaterial = new MeshStandardMaterial({
        map: blockSpinnerTexture,
    });
    [blockSpinnerTexture, blockSpinnerTexture2, blockSpinnerTexture3, blockSpinnerTexture4, blockSpinnerTexture5, blockSpinnerTexture6].forEach((texture, index) => {
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;
        if (index === 0 || index === 1){
        }
        if (index === 2){
            texture.repeat.set(1, 1);
        }
    });
    const materials = [
        new MeshStandardMaterial({
            map: blockSpinnerTexture,
        }),
        new MeshStandardMaterial({
            map: blockSpinnerTexture2,
        }),
        new MeshStandardMaterial({
            map: blockSpinnerTexture3,
        }),
        new MeshStandardMaterial({
            map: blockSpinnerTexture4,
        }),
        new MeshStandardMaterial({
            map: blockSpinnerTexture5,
        }),
        new MeshStandardMaterial({
            map: blockSpinnerTexture6,
        })
    ]
    const obstacle = useRef();
    const [speed] = useState(
        () => (Math.random() + difficulty + 0.5) * (Math.random() < 0.5 ? -1 : 1)
    );

    useFrame((state) => {
        const time = state.clock.getElapsedTime();
        const rotation = new THREE.Quaternion();
        rotation.setFromEuler(new THREE.Euler(0, time * speed, 0));
        if (obstacle.current) {
            obstacle.current.setNextKinematicRotation(rotation);
        }
    });

    return (
        <group position={position}>
            <mesh
                geometry={boxGeometry}
                material={texturedMaterialFloor}
                position={[0, -0.2, 0]}
                scale={[
                    blockDimensions.width,
                    blockDimensions.height,
                    blockDimensions.length,
                ]}
                receiveShadow
            />
            <RigidBody
                ref={obstacle}
                type="kinematicPosition"
                position={[0, 0.4, 0]}
                restitution={0.2}
                friction={0}
            >
                <mesh
                    geometry={boxGeometry}
                    material={materials}
                    scale={[4.5, 0.3, 0.3]}
                >
                </mesh>
            </RigidBody>
        </group>
    );
}

export function BlockDoubleSpinner({ position = [0, 0, 0], difficulty }) {
    const floorTexture = useLoader(TextureLoader, "./textures/actual-new/floor.jpg");
    const blockDoubleSpinnerTexture = useLoader(TextureLoader, "./textures/actual-new/block-side-02.jpg"); // left
    const blockDoubleSpinnerTexture2 = useLoader(TextureLoader, "./textures/actual-new/block-side-02.jpg"); // right
    const blockDoubleSpinnerTexture3 = useLoader(TextureLoader, "./textures/actual-new/block-02.jpg"); // top
    const blockDoubleSpinnerTexture4 = useLoader(TextureLoader, "./textures/actual-new/block-02.jpg"); // floor
    const blockDoubleSpinnerTexture5 = useLoader(TextureLoader, "./textures/actual-new/block-02.jpg"); // front
    const blockDoubleSpinnerTexture6 = useLoader(TextureLoader, "./textures/actual-new/block-02.jpg"); // back

    [blockDoubleSpinnerTexture, blockDoubleSpinnerTexture2, blockDoubleSpinnerTexture3, blockDoubleSpinnerTexture4, blockDoubleSpinnerTexture5, blockDoubleSpinnerTexture6].forEach((texture, index) => {
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;
        if (index === 0 || index === 1){
        }
        if (index === 2){
            texture.repeat.set(1, 1);
        }
    });
    const materials = [
        new MeshStandardMaterial({
            map: blockDoubleSpinnerTexture,
        }),
        new MeshStandardMaterial({
            map: blockDoubleSpinnerTexture2,
        }),
        new MeshStandardMaterial({
            map: blockDoubleSpinnerTexture3,
        }),
        new MeshStandardMaterial({
            map: blockDoubleSpinnerTexture4,
        }),
        new MeshStandardMaterial({
            map: blockDoubleSpinnerTexture5,
        }),
        new MeshStandardMaterial({
            map: blockDoubleSpinnerTexture6,
        })
    ]


    const texturedMaterialFloor = new THREE.MeshStandardMaterial({
        map: floorTexture,
    });

  const obstacle1 = useRef();
  const obstacle2 = useRef();

  const [direction] = useState(() => (Math.random() < 0.5 ? -1 : 1));
  const [speed] = useState(() => difficulty * 2 * direction);

  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    const rotation1 = new THREE.Quaternion();
    rotation1.setFromEuler(new THREE.Euler(0, time * speed, 0));
    obstacle1.current.setNextKinematicRotation(rotation1);

    const rotation2 = new THREE.Quaternion();
    rotation2.setFromEuler(new THREE.Euler(0, time * -speed, 0));
    obstacle2.current.setNextKinematicRotation(rotation2);
  });

  return (
    <group position={position}>
      <mesh
        geometry={boxGeometry}
        material={texturedMaterialFloor}
        position={[0, -0.2, 0]}
        scale={[
          blockDimensions.width,
          blockDimensions.height,
          blockDimensions.length,
        ]}
        receiveShadow
      />
      <RigidBody
        ref={obstacle1}
        type="kinematicPosition"
        position={[blockDimensions.width / 4, 0.4, 0]}
        restitution={0.2}
        friction={0}
      >
        <mesh
          geometry={boxGeometry}
          material={materials}
          scale={[2.25, 0.3, 0.3]}
          castShadow
          receiveShadow
        />
      </RigidBody>
      <RigidBody
        ref={obstacle2}
        type="kinematicPosition"
        position={[-blockDimensions.width / 4, 0.4, 0]}
        restitution={0.2}
        friction={0}
      >
        <mesh
          geometry={boxGeometry}
          material={materials}
          scale={[1.8, 0.3, 0.3]}
          castShadow
          receiveShadow
        />
      </RigidBody>
    </group>
  );
}

export function BlockLimbo({ position = [0, 0, 0], difficulty }) {
    const floorTexture = useLoader(TextureLoader, "./textures/actual-new/floor.jpg");
    const blockLimboTexture = useLoader(TextureLoader, "./textures/actual-new/block-side-01.jpg"); // left
    const blockLimboTexture2 = useLoader(TextureLoader, "./textures/actual-new/block-side-01.jpg"); // right
    const blockLimboTexture3 = useLoader(TextureLoader, "./textures/actual-new/block-01.jpg"); // top
    const blockLimboTexture4 = useLoader(TextureLoader, "./textures/actual-new/block-01.jpg"); // floor
    const blockLimboTexture5 = useLoader(TextureLoader, "./textures/actual-new/block-01.jpg"); // front
    const blockLimboTexture6 = useLoader(TextureLoader, "./textures/actual-new/block-01.jpg"); // back

    floorTexture.wrapS = RepeatWrapping;
    floorTexture.wrapT = RepeatWrapping;
    floorTexture.repeat.set(1, 1);

    const texturedMaterialFloor = new MeshStandardMaterial({
        map: floorTexture,
    });

    const obstacleMaterial = new MeshStandardMaterial({
        map: blockLimboTexture,
    });
    [blockLimboTexture, blockLimboTexture2, blockLimboTexture3, blockLimboTexture4, blockLimboTexture5, blockLimboTexture6].forEach((texture, index) => {
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;
        if (index === 0 || index === 1){
        }
        if (index === 2){
        }
    });
    const materials = [
        new MeshStandardMaterial({
            map: blockLimboTexture,
        }),
        new MeshStandardMaterial({
            map: blockLimboTexture2,
        }),
        new MeshStandardMaterial({
            map: blockLimboTexture3,
        }),
        new MeshStandardMaterial({
            map: blockLimboTexture4,
        }),
        new MeshStandardMaterial({
            map: blockLimboTexture5,
        }),
        new MeshStandardMaterial({
            map: blockLimboTexture6,
        })
    ]
    const obstacle = useRef();
  const [timeOffset] = useState(() => Math.random() * Math.PI * 2);

  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    const y = Math.sin(1.5 * difficulty * time + timeOffset) + 1.3;
    obstacle.current.setNextKinematicTranslation({
      x: position[0],
      y: position[1] + y,
      z: position[2],
    });
  });

  return (
    <group position={position}>
      <mesh
        geometry={boxGeometry}
        material={texturedMaterialFloor}
        position={[0, -0.2, 0]}
        scale={[
          blockDimensions.width,
          blockDimensions.height,
          blockDimensions.length,
        ]}
        receiveShadow
      />
      <RigidBody
        ref={obstacle}
        type="kinematicPosition"
        restitution={0.2}
        friction={0}
      >
        <mesh
          geometry={boxGeometry}
          material={materials}
          scale={[4, 0.3, 0.3]}
          castShadow
          receiveShadow
        />
      </RigidBody>
    </group>
  );
}

export function BlockDoubleLimbo({ position = [0, 0, 0], difficulty }) {
    const floorTexture = useLoader(TextureLoader, "./textures/actual-new/floor.jpg");
    const blockDoubleLimboTexture = useLoader(TextureLoader, "./textures/actual-new/block-side-01.jpg"); // left
    const blockDoubleLimboTexture2 = useLoader(TextureLoader, "./textures/actual-new/block-side-01.jpg"); // right
    const blockDoubleLimboTexture3 = useLoader(TextureLoader, "./textures/actual-new/block-01.jpg"); // top
    const blockDoubleLimboTexture4 = useLoader(TextureLoader, "./textures/actual-new/block-01.jpg"); // floor
    const blockDoubleLimboTexture5 = useLoader(TextureLoader, "./textures/actual-new/block-01.jpg"); // front
    const blockDoubleLimboTexture6 = useLoader(TextureLoader, "./textures/actual-new/block-01.jpg"); // back

    floorTexture.wrapS = RepeatWrapping;
    floorTexture.wrapT = RepeatWrapping;
    floorTexture.repeat.set(1, 1);

    const texturedMaterialFloor = new MeshStandardMaterial({
        map: floorTexture,
    });

    const obstacleMaterial = new MeshStandardMaterial({
        map: blockDoubleLimboTexture,
    });
    [blockDoubleLimboTexture, blockDoubleLimboTexture2, blockDoubleLimboTexture3, blockDoubleLimboTexture4, blockDoubleLimboTexture5, blockDoubleLimboTexture6].forEach((texture, index) => {
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;
        if (index === 0 || index === 1){
        }
        if (index === 2){
        }
    });
    const materials = [
        new MeshStandardMaterial({
            map: blockDoubleLimboTexture,
        }),
        new MeshStandardMaterial({
            map: blockDoubleLimboTexture2,
        }),
        new MeshStandardMaterial({
            map: blockDoubleLimboTexture3,
        }),
        new MeshStandardMaterial({
            map: blockDoubleLimboTexture4,
        }),
        new MeshStandardMaterial({
            map: blockDoubleLimboTexture5,
        }),
        new MeshStandardMaterial({
            map: blockDoubleLimboTexture6,
        })
    ]
  const obstacle1 = useRef();
  const obstacle2 = useRef();
  const [timeOffset] = useState(() => Math.random() * Math.PI * 2);

  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    const y1 = 0.3 * Math.sin(difficulty * 1.5 * time + timeOffset) + 1.3;
    obstacle1.current.setNextKinematicTranslation({
      x: position[0],
      y: position[1] + y1 + 0.2,
      z: position[2],
    });

    const y2 = -0.3 * Math.sin(1.5 * difficulty * time + timeOffset) + 1.3;
    obstacle2.current.setNextKinematicTranslation({
      x: position[0],
      y: position[1] + y2 - 0.8,
      z: position[2],
    });
  });

  return (
    <group position={position}>
      <mesh
        geometry={boxGeometry}
        material={texturedMaterialFloor}
        position={[0, -0.2, 0]}
        scale={[
          blockDimensions.width,
          blockDimensions.height,
          blockDimensions.length,
        ]}
        receiveShadow
      />
      <RigidBody
        ref={obstacle1}
        type="kinematicPosition"
        restitution={0.2}
        friction={0}
      >
        <mesh
          geometry={boxGeometry}
          material={materials}
          scale={[4, 0.3, 0.3]}
          castShadow
          receiveShadow
        />
      </RigidBody>
      <RigidBody
        ref={obstacle2}
        type="kinematicPosition"
        restitution={0.2}
        friction={0}
      >
        <mesh
          geometry={boxGeometry}
          material={materials}
          scale={[4, 0.3, 0.3]}
          castShadow
          receiveShadow
        />
      </RigidBody>
    </group>
  );
}

export function BlockPlatformLimbo({ position = [0, 0, 0], difficulty }) {
    const floorTexture = useLoader(TextureLoader, "./textures/actual-new/floor.jpg");
    floorTexture.wrapS = THREE.RepeatWrapping;
    floorTexture.wrapT = THREE.RepeatWrapping;
    floorTexture.repeat.set(1, 1);

    const texturedMaterialFloor = new THREE.MeshStandardMaterial({
        map: floorTexture,
    });

    const blockPlatformLimboTexture = useLoader(
        THREE.TextureLoader,
        "./textures/actual-new/block-01.jpg"
    );
    const obstacleMaterial = new MeshStandardMaterial({
        map: blockPlatformLimboTexture,
    });
  const obstacle = useRef();
  const [timeOffset] = useState(() => Math.random() * Math.PI * 2);

  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    const y = Math.sin(1.5 * difficulty * time + timeOffset) + 1.3;
    obstacle.current.setNextKinematicTranslation({
      x: position[0],
      y: position[1] + y,
      z: position[2],
    });
  });

  return (
    <group position={position}>
      <mesh
        geometry={boxGeometry}
        material={texturedMaterialFloor}
        position={[0, -0.2, 0]}
        scale={[
          blockDimensions.width,
          blockDimensions.height,
          blockDimensions.length,
        ]}
        receiveShadow
      />
      <RigidBody
        ref={obstacle}
        type="kinematicPosition"
        restitution={0.2}
        friction={0}
      >
        <mesh
          geometry={boxGeometry}
          material={obstacleMaterial}
          scale={[4, 0.3, 3]}
          castShadow
          receiveShadow
        />
      </RigidBody>
    </group>
  );
}

export function BlockRamp({ position = [0, 0, 0], difficulty }) {
    const floorTexture = useLoader(TextureLoader, "./textures/actual-new/floor.jpg");
    floorTexture.wrapS = THREE.RepeatWrapping;
    floorTexture.wrapT = THREE.RepeatWrapping;
    floorTexture.repeat.set(1, 1);

    const texturedMaterialFloor = new THREE.MeshStandardMaterial({
        map: floorTexture,
    });
    const blockRampTexture = useLoader(
        THREE.TextureLoader,
        "./textures/actual-new/block-01.jpg"
    );
    const obstacleMaterial = new MeshStandardMaterial({
        map: blockRampTexture,
    });

  return (
    <group position={position}>
      <mesh
        geometry={boxGeometry}
        material={texturedMaterialFloor}
        position={[0, -0.2, 0]}
        scale={[
          blockDimensions.width,
          blockDimensions.height,
          blockDimensions.length,
        ]}
        receiveShadow
      />
      <RigidBody type="kinematicPosition" restitution={0.2} friction={0}>
        <mesh
          geometry={boxGeometry}
          material={obstacleMaterial}
          position={[0, 0.4, 0]}
          scale={[4, 0.3, 1.5]}
          rotation={[0.75, 0, 0]}
          castShadow
          receiveShadow
        />
      </RigidBody>
    </group>
  );
}

export function BlockSlidingWall({ position = [0, 0, 0], difficulty }) {
    const floorTexture = useLoader(TextureLoader, "./textures/actual-new/floor.jpg");
    const blockSlidingWallTexture = useLoader(TextureLoader, "./textures/actual-new/block-side-03.jpg"); // left
    const blockSlidingWallTexture2 = useLoader(TextureLoader, "./textures/actual-new/block-side-03.jpg"); // right
    const blockSlidingWallTexture3 = useLoader(TextureLoader, "./textures/actual-new/block-side-03-top-floor.jpg"); // top
    const blockSlidingWallTexture4 = useLoader(TextureLoader, "./textures/actual-new/block-side-03-top-floor.jpg"); // floor
    const blockSlidingWallTexture5 = useLoader(TextureLoader, "./textures/actual-new/block-03.jpg"); // front
    const blockSlidingWallTexture6 = useLoader(TextureLoader, "./textures/actual-new/block-03.jpg"); // back

    [blockSlidingWallTexture, blockSlidingWallTexture2, blockSlidingWallTexture3, blockSlidingWallTexture4, blockSlidingWallTexture5, blockSlidingWallTexture6].forEach((texture, index) => {
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;
        if (index === 0 || index === 1){
        }
        if (index === 2 || index === 3){
            texture.repeat.set(3, 1);
        }
    });
    const materials = [
        new MeshStandardMaterial({
            map: blockSlidingWallTexture,
        }),
        new MeshStandardMaterial({
            map: blockSlidingWallTexture2,
        }),
        new MeshStandardMaterial({
            map: blockSlidingWallTexture3,
        }),
        new MeshStandardMaterial({
            map: blockSlidingWallTexture4,
        }),
        new MeshStandardMaterial({
            map: blockSlidingWallTexture5,
        }),
        new MeshStandardMaterial({
            map: blockSlidingWallTexture6,
        })
    ]


    const texturedMaterialFloor = new THREE.MeshStandardMaterial({
        map: floorTexture,
    });

  const obstacle = useRef();
  const [timeOffset] = useState(() => Math.random() * Math.PI * 2);

  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    const x = Math.sin(difficulty * 1.5 * time + timeOffset) * 1.25;
    obstacle.current.setNextKinematicTranslation({
      x: position[0] + x,
      y: position[1] + 0.75,
      z: position[2],
    });
  });

  return (
    <group position={position}>
      <mesh
        geometry={boxGeometry}
        material={texturedMaterialFloor}
        position={[0, -0.2, 0]}
        scale={[
          blockDimensions.width,
          blockDimensions.height,
          blockDimensions.length,
        ]}
        receiveShadow
      />
      <RigidBody
        ref={obstacle}
        type="kinematicPosition"
        restitution={0.2}
        friction={0}
      >
        <mesh
          geometry={boxGeometry}
          material={materials}
          scale={[1.7, 1.8, 0.3]}
          castShadow
          receiveShadow
        />
      </RigidBody>
    </group>
  );
}

export function BlockDoubleSlidingWall({ position = [0, 0, 0], difficulty }) {
    const floorTexture = useLoader(TextureLoader, "./textures/actual-new/floor.jpg");
    const blockDoubleSlidingWallTexture = useLoader(TextureLoader, "./textures/actual-new/block-side-04.jpg"); // left
    const blockDoubleSlidingWallTexture2 = useLoader(TextureLoader, "./textures/actual-new/block-side-04.jpg"); // right
    const blockDoubleSlidingWallTexture3 = useLoader(TextureLoader, "./textures/actual-new/block-side-03-top-floor.jpg"); // top
    const blockDoubleSlidingWallTexture4 = useLoader(TextureLoader, "./textures/actual-new/block-side-03-top-floor.jpg"); // floor
    const blockDoubleSlidingWallTexture5 = useLoader(TextureLoader, "./textures/actual-new/block-04.jpg"); // front
    const blockDoubleSlidingWallTexture6 = useLoader(TextureLoader, "./textures/actual-new/block-04.jpg"); // back

    [blockDoubleSlidingWallTexture, blockDoubleSlidingWallTexture2, blockDoubleSlidingWallTexture3, blockDoubleSlidingWallTexture4, blockDoubleSlidingWallTexture5, blockDoubleSlidingWallTexture6].forEach((texture, index) => {
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;
        if (index === 0 || index === 1){
        }
        if (index === 2 || index === 3){
            texture.repeat.set(2, 1);
        }
    });
    const materials = [
        new MeshStandardMaterial({
            map: blockDoubleSlidingWallTexture,
        }),
        new MeshStandardMaterial({
            map: blockDoubleSlidingWallTexture2,
        }),
        new MeshStandardMaterial({
            map: blockDoubleSlidingWallTexture3,
        }),
        new MeshStandardMaterial({
            map: blockDoubleSlidingWallTexture4,
        }),
        new MeshStandardMaterial({
            map: blockDoubleSlidingWallTexture5,
        }),
        new MeshStandardMaterial({
            map: blockDoubleSlidingWallTexture6,
        })
    ]


    const texturedMaterialFloor = new THREE.MeshStandardMaterial({
        map: floorTexture,
    });

    const wall1 = useRef();
  const wall2 = useRef();

  const [timeOffset] = useState(() => Math.random() * Math.PI * 2);

  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    const x1 = Math.sin(difficulty * 2 * time + timeOffset) * 0.5 + 1;
    wall1.current.setNextKinematicTranslation({
      x: position[0] + x1,
      y: position[1] + 0.75,
      z: position[2],
    });

    const x2 = -Math.sin(difficulty * 2 * time + timeOffset) * 0.5 - 1;
    wall2.current.setNextKinematicTranslation({
      x: position[0] + x2,
      y: position[1] + 0.75,
      z: position[2],
    });
  });

  return (
    <group position={position}>
      <mesh
        geometry={boxGeometry}
        material={texturedMaterialFloor}
        position={[0, -0.2, 0]}
        scale={[
          blockDimensions.width,
          blockDimensions.height,
          blockDimensions.length,
        ]}
        receiveShadow
      />
      <RigidBody
        ref={wall1}
        type="kinematicPosition"
        restitution={0.2}
        friction={0}
      >
        <mesh
          geometry={boxGeometry}
          material={materials}
          scale={[1, 1.8, 0.3]}
          castShadow
          receiveShadow
        />
      </RigidBody>
      <RigidBody
        ref={wall2}
        type="kinematicPosition"
        restitution={0.2}
        friction={0}
      >
        <mesh
          geometry={boxGeometry}
          material={materials}
          scale={[1, 1.8, 0.3]}
          castShadow
          receiveShadow
        />
      </RigidBody>
    </group>
  );
}

/**
 * BlockEnd
 */
export function BlockEnd({ position = [0, 0, 0] }) {
    const floorTexture = useLoader(TextureLoader, "./textures/actual-new/floor.jpg");
    floorTexture.wrapS = THREE.RepeatWrapping;
    floorTexture.wrapT = THREE.RepeatWrapping;
    floorTexture.repeat.set(1, 1);

    const texturedMaterialFloor = new THREE.MeshStandardMaterial({
        map: floorTexture,
    });

  const { end } = useGame();

  function onHit() {
    end();
  }

  return (
    <group position={position}>
      <mesh
        geometry={boxGeometry}
        material={texturedMaterialFloor}
        position={[0, -0.2, 0]}
        scale={[
          blockDimensions.width,
          blockDimensions.height,
          blockDimensions.length,
        ]}
        receiveShadow
      />
      <Float
        speed={25}
        rotationIntensity={0.25}
        floatIntensity={0.25}
        floatingRange={[-0.01, 0.01]}
      >
        <RigidBody
          type="fixed"
          colliders="trimesh"
          position={[0, 1.05, 0]}
          rotation={[0, Math.PI / 2, 0]}
          restitution={0.2}
          friction={0}
          onCollisionEnter={onHit}
        >
          <EndModel scale={0.012} />
        </RigidBody>
      </Float>
    </group>
  );
}
