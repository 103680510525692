import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import injectedModule, {ProviderLabel} from "@web3-onboard/injected-wallets";
import Onboard from "@web3-onboard/core";
import { Web3 } from "web3";
import {getLocalStorage, setLocalStorage, removeLocalStorage, myFetch, generateHash, formatMilliseconds} from "./utils";
import { levelsObj } from "./levels.js";

const infuraKey = import.meta.env.VITE_INFURAKEYKey;
const MAINNET_RPC_URL = `https://mainnet.infura.io/v3/${infuraKey}`

const injected = injectedModule({
    sort: wallets => {
        const metaMask = wallets.find(
            ({ label }) => label === ProviderLabel.MetaMask
        )
        const coinbase = wallets.find(
            ({ label }) => label === ProviderLabel.Coinbase
        )

        return (
            [
                coinbase,
                ...wallets,
                ...wallets.filter(
                    ({ label }) =>
                        label !== ProviderLabel.MetaMask
                )
            ]
                .filter(wallet => wallet)
        )
    },
});

const onboard = Onboard({
    wallets: [injected],
    chains: [
        {
            id: "0x1",
            token: "ETH",
            namespace: "evm",
            label: "Ethereum Mainnet",
            rpcUrl: MAINNET_RPC_URL
        },
        {
            id: "101",
            token: "Solana",
            namespace: "evm",
            label: "Solana Mainnet",
        },

    ],
    accountCenter: {
        desktop: {
            position: 'topLeft',
            enabled: false,
        },
        mobile: {
            position: 'topLeft',
            enabled: false,
        }
    },
});

const useUser = create(
    subscribeWithSelector((set, get) => ({
        isLogin: false,
        isLoading: false,
        walletAddress: null,
        level: null,
        isConnecting: false,
        leaderBoard: [],
        disconnectMetamask: () => {
            removeLocalStorage('access_token');
            set({ isLogin: false, walletAddress: null, level: null })
        },
        getLeaderBoard: async () => {
            try {
                const token = getLocalStorage('access_token');
                if (token){
                    const leaderboard = await myFetch('/user/leaderboard', 'GET', token);
                    if (leaderboard.length > 0){
                        const formatedLeaderboard = leaderboard.map((leaderboardItem, index) => {
                            return {
                                id: index,
                                place: leaderboardItem.place,
                                address: '...' + leaderboardItem.walletAddress.slice(-7).toLowerCase(),
                                time: formatMilliseconds(leaderboardItem.totalTime),
                                level: leaderboardItem.lastLevel,
                                score: leaderboardItem.score,
                                isMe: leaderboardItem.isOwner,
                            }
                        })
                        set(() => {
                            return { isLoading: false, leaderBoard: formatedLeaderboard };
                        });
                    }
                }
            }catch (e) {
                console.error(e);
                return [];
            }
        },
        verify: async () => {
            try {
                set(() => ({  isLoading: true }));
                const token = getLocalStorage('access_token');
                if (token) {
                    const { user } = await myFetch('/user/verify', 'GET', token);
                    let walletAddress = '';
                    let isLogin = false;
                    let level = false;
                    if (user) {
                        walletAddress = user.walletAddress;
                        isLogin = true;
                        level = user.level;
                    } else {
                        removeLocalStorage('access_token');
                        walletAddress = '';
                        isLogin = false;
                        level = '';
                    }
                    set(() => {
                        return { walletAddress, isLogin, level, isLoading: false };
                    });
                }{
                    set(() => {
                        return { isLoading: false };
                    });
                }
            } catch (e) {
                console.log(e, 'e')
                removeLocalStorage('access_token');
                return { walletAddress: "" };
            }
        },
        login: async () => {
            try {
                const token = getLocalStorage('access_token');
                if (token) {
                    await this.verify();
                } else {
                    set(() => ({ isConnecting: true }));
                    const wallets = await onboard.connectWallet();
                    const { accounts, chains, provider } = wallets[0];
                    const message = 'Authentication for AddictionBetCopy';
                    const web3 = new Web3(provider);
                    const signature = await web3.eth.personal.sign(message, accounts[0].address, '');
                    if (web3) {
                        const [address] = await web3.eth.requestAccounts()
                        const message = 'Authentication for AddictionBetCopy';
                        const signature = await web3.eth.personal.sign(message, address, '');
                        const { token, user } = await myFetch('/user/login', 'POST', null, {message, address, signature});

                        if (token && user) {
                            setLocalStorage('access_token', token);
                            set(() => {
                                return { walletAddress: user.walletAddress, isLogin: true, level: user.level, isLoading: false, isConnecting: false };
                            });
                        }
                    }
                }
            } catch (e) {
                removeLocalStorage('access_token');
                set(() => ({ isConnecting: false, isLoading: false }))
            }
        },
        loginSolana: async ({address, sign, payload}) => {
            try {
                const token = getLocalStorage('access_token');
                if (token) {
                    await this.verify();
                } else {
                    set(() => ({ isConnecting: true }));

                    const { token, user } = await myFetch('/user/login-solana', 'POST', null, {payload, address, sign});
                    if (token && user) {
                        setLocalStorage('access_token', token);
                        set(() => {
                            return { walletAddress: user.walletAddress, isLogin: true, level: user.level, isLoading: false, isConnecting: false };
                        });
                    }
                }
            } catch (e) {
                removeLocalStorage('access_token');
                set(() => ({ isConnecting: false, isLoading: false }))
            }
        },
        loginWithoutProgram: async (address) => {
            try {
                console.log(address, "address")
                set(() => ({ isConnecting: true, isLoading: true }));

                const { token, user } = await myFetch('/user/login-wp', 'POST', null, {address});
                if (token && user) {
                    setLocalStorage('access_token', token);
                    set(() => {
                        return { walletAddress: user.walletAddress, isLogin: true, level: user.level, isLoading: false, isConnecting: false };
                    });
                }
            } catch (e) {
                removeLocalStorage('access_token');
                set(() => ({ isConnecting: false, isLoading: false }))
            }
        },
        updateLevel: async (levelTime) => {
            try {
                const levelHashes = {
                    'level-1': import.meta.env.VITE_GMLS1 || '',
                    'level-2': import.meta.env.VITE_GMLS2 || '',
                    'level-3': import.meta.env.VITE_GMLS3 || '',
                    'level-4': import.meta.env.VITE_GMLS4 || '',
                    'level-5': import.meta.env.VITE_GMLS5 || '',
                    'level-6': import.meta.env.VITE_GMLS6 || '',
                    'level-7': import.meta.env.VITE_GMLS7 || '',
                    'level-8': import.meta.env.VITE_GMLS8 || '',
                    'level-9': import.meta.env.VITE_GMLS9 || '',
                    'level-10': import.meta.env.VITE_GMLS10 || '',
                    'level-11': import.meta.env.VITE_GMLS11 || '',
                    'level-12': import.meta.env.VITE_GMLS12 || '',
                    'level-13': import.meta.env.VITE_GMLS13 || '',
                    'level-14': import.meta.env.VITE_GMLS14 || '',
                    'level-15': import.meta.env.VITE_GMLS15 || '',
                    'level-16': import.meta.env.VITE_GMLS16 || '',
                    'level-17': import.meta.env.VITE_GMLS17 || '',
                    'level-18': import.meta.env.VITE_GMLS18 || '',
                    'level-19': import.meta.env.VITE_GMLS19 || '',
                    'level-20': import.meta.env.VITE_GMLS20 || '',
                    'level-21': import.meta.env.VITE_GMLS21 || '',
                    'level-22': import.meta.env.VITE_GMLS22 || '',
                    'level-23': import.meta.env.VITE_GMLS23 || '',
                    'level-24': import.meta.env.VITE_GMLS24 || '',
                    'level-25': import.meta.env.VITE_GMLS25 || '',
                    'level-26': import.meta.env.VITE_GMLS26 || '',
                    'level-27': import.meta.env.VITE_GMLS27 || '',
                    'level-28': import.meta.env.VITE_GMLS28 || '',
                    'level-29': import.meta.env.VITE_GMLS29 || '',
                    'level-30': import.meta.env.VITE_GMLS30 || '',
                    'level-31': import.meta.env.VITE_GMLS31 || '',
                    'level-32': import.meta.env.VITE_GMLS32 || '',
                }
                const level = levelsObj[get().level];
                const token = getLocalStorage('access_token');
                const fHStr = levelHashes[level.nextLevel] || '';
                const walletAddress = get().walletAddress || '';
                const h = generateHash(fHStr.toLocaleString() + walletAddress.toLocaleString());


                const { user } = await myFetch('/user', 'PUT', token, {
                    level: level.nextLevel,
                    levelHash: h,
                    levelCompletionTime: levelTime,
                    levelNumber: level.levelNumber
                });

                if (token && user) {
                    set( (state) => {
                        return { level: user.level };
                    });
                }
            } catch (e) {
                console.log(e);
            }
        }
    }))
);

export default useUser;