import React, { useCallback, useEffect, useRef, useState } from "react";
import { addEffect } from "@react-three/fiber";
import useGame from "../stores/useGame.js";
import useAudio from "../stores/useAudio.js";
import LoadNextLevel from "../assets/load-next-level.png";
import Logo from "../assets/logo-new.png";
import useUser from "../stores/useUser.js";
import { levelsObj } from "../stores/levels.js";

export default function Interface() {
  const time = useRef();
  const { mode, setMode, restart, phase, setIsInGame, next, levelTime } = useGame();
  const [scorePlus, setScorePlus] = useState(0);
  const { audio, toggleAudio } = useAudio();
  // const { updateLevel, level, is } = useUser();
  const {
    updateLevel,
    level,
    isLogin,
  } = useUser((state) => state);
  const [modeName, setModeName] = useState(mode);

  useEffect(() => {
    switch (mode) {
      case "random":
        setModeName("Random");
        break;
      case "tour":
        setModeName("Tour");
        break;
      case "adventure":
        setModeName("Adventure");
        break;
    }
  }, [mode]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.code === "Escape") {
        setIsModalOpen((prev) => !prev);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isModalOpen, setIsModalOpen]);

  const clearData = () => {
    window.localStorage.clear();
  };
  useEffect(() => {
    // let timer;
    if (phase === 'ended'){
      setTimeout(() => {
        console.log('useEffect -> handleNextLevelClick')
        handleNextLevelClick()
      }, 2000)
    }
  }, [phase]);
  const handleRestart = () => {
    restart();
  };

  const [selectedMode, setSelectedMode] = useState(null);

  useEffect(() => {
    setSelectedMode(modeOptions.find((m) => m.name === mode));
  }, []);

  useEffect(() => {
    if (levelTime && level){
      const levelNumber = levelsObj[level].levelNumber;
      const scorePlusNew = (levelNumber * levelNumber / (levelTime / 1000)) * 1000;
      setScorePlus(Number(scorePlusNew.toFixed(0)))
    }
  }, [level, levelTime]);

  function handleModeClick(mode) {
    setSelectedMode(mode);
  }

  const handleNextLevelClick =  useCallback(()=> {
    const currentLevel = levelsObj[level];
    const nextLevel = levelsObj[currentLevel.nextLevel];
    const difficulty = nextLevel?.difficulty || 1;
    const blocksCount = nextLevel?.blocksCount || 5;
    next(difficulty, blocksCount);
    updateLevel(levelTime);
  }, [level, levelTime])

  useEffect(() => {
    let elapsedTime = 0;
    const unsubscribeEffect = addEffect(() => {
      const state = useGame.getState();
      if (state.phase === "playing") {
        elapsedTime = Date.now() - state.startTime;
      } else if (state.phase === "ended") {
        elapsedTime = state.endTime - state.startTime;
      }

      elapsedTime /= 1000;
      elapsedTime = elapsedTime.toFixed(2);
      
      if (time.current) {
        time.current.textContent = elapsedTime;
      }
    });

    return () => {
      unsubscribeEffect();
    };
  }, []);

  let modes = [
    { id: "0", text: "Random", name: "random" },
    { id: "1", text: "Tour", name: "tour" },
    { id: "2", text: "Adventure", name: "adventure" },
  ];

  const modeOptions = modes.map((mode) => (
    <div
      key={mode.id}
      className={`mode-selection ${
        selectedMode && selectedMode.name === mode.name ? "selected-mode" : ""
      }`}
      onClick={() => {
        handleModeClick(mode);
        setMode(`${mode.name}`);
        window.localStorage.setItem("mode", `"${mode.name}"`);
        handleRestart();
      }}
    >
      {mode.text}
    </div>
  ));

  const endGame = () => {
    setTimeout(() => {
      setIsInGame(false)
    }, [])
  }
  
  return (
    <div className={`interface`}>
      <img className="connect-logo" src={Logo} alt="Logo" />
      {phase === "ended" && isLogin && (
          <div className="loading-lever-interval">
            <div className="loading-lever-interval-middle">
              <img className="loading-lever-interval-img" src={LoadNextLevel} alt="LoadNextLevel"/>
              <div className="score-level">
                Score for this level:
                + {scorePlus}
              </div>
            </div>
          </div>
      )}
      <div className="control-buttons">
        <div className="top-control-wrapper">
          <div className="control-button" id="sound" onClick={() => {
            if (audio) {
              toggleAudio();
            }
            endGame()
          }}>
            <img src="./icons/button_close.svg"/>
          </div>
          <div className="control-button" id="sound" onClick={toggleAudio}>
            {audio ? (
                <img src="./icons/button_mute.svg"/>
            ) : (
                <img src="./icons/button_mute_2.svg"/>
            )}
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="controls">
          <div className="level-container">
            <div className="mode">Level</div>
            <div className="bottom-label">{level ? level.split('-')[1] : ''}</div>
          </div>
        </div>
        <div className="bottom-right">
          <div className="time-container">
            <div className="bottom-label">Time</div>
            <div className="time" ref={time}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
