export const levelsObj = {
    "level-1" : {
        difficulty: 1,
        blocksCount: 5,
        nextLevel:'level-2',
        levelNumber: 1,
    },
    "level-2" : {
        difficulty: 1,
        blocksCount: 10,
        nextLevel: 'level-3',
        levelNumber: 2,
    },
    "level-3" : {
        difficulty: 1,
        blocksCount: 15,
        nextLevel: 'level-4',
        levelNumber: 3,
    },
    "level-4" : {
        difficulty: 1,
        blocksCount: 20,
        nextLevel: 'level-5',
        levelNumber: 4,
    },
    "level-5" : {
        difficulty: 1,
        blocksCount: 30,
        nextLevel: 'level-6',
        levelNumber: 5,
    },
    "level-6" : {
        difficulty: 1,
        blocksCount: 40,
        nextLevel: 'level-7',
        levelNumber: 6,
    },
    "level-7" : {
        difficulty: 1,
        blocksCount: 50,
        nextLevel: 'level-8',
        levelNumber: 7,
    },
    "level-8" : {
        difficulty: 1,
        blocksCount: 100,
        nextLevel: 'level-9',
        levelNumber: 8,
    },
    "level-9" : {
        difficulty: 3,
        blocksCount: 5,
        nextLevel: 'level-10',
        levelNumber: 9,
    },
    "level-10" : {
        difficulty: 3,
        blocksCount: 10,
        nextLevel: 'level-11',
        levelNumber: 10,
    },
    "level-11" : {
        difficulty: 3,
        blocksCount: 15,
        nextLevel: 'level-12',
        levelNumber: 11,
    },
    "level-12" : {
        difficulty: 3,
        blocksCount: 20,
        nextLevel: 'level-13',
        levelNumber: 12,
    },
    "level-13" : {
        difficulty: 3,
        blocksCount: 30,
        nextLevel: 'level-14',
        levelNumber: 13,
    },
    "level-14" : {
        difficulty: 3,
        blocksCount: 40,
        nextLevel: 'level-15',
        levelNumber: 14,
    },
    "level-15" : {
        difficulty: 3,
        blocksCount: 50,
        nextLevel: 'level-16',
        levelNumber: 15,
    },
    "level-16" : {
        difficulty: 3,
        blocksCount: 100,
        nextLevel: 'level-17',
        levelNumber: 16,
    },
    "level-17" : {
        difficulty: 5,
        blocksCount: 5,
        nextLevel: 'level-18',
        levelNumber: 17,
    },
    "level-18" : {
        difficulty: 5,
        blocksCount: 10,
        nextLevel: 'level-19',
        levelNumber: 18,
    },
    "level-19" : {
        difficulty: 5,
        blocksCount: 15,
        nextLevel: 'level-20',
        levelNumber: 19,
    },
    "level-20" : {
        difficulty: 5,
        blocksCount: 20,
        nextLevel: 'level-21',
        levelNumber: 20,
    },
    "level-21" : {
        difficulty: 5,
        blocksCount: 30,
        nextLevel: 'level-22',
        levelNumber: 21,
    },
    "level-22" : {
        difficulty: 5,
        blocksCount: 40,
        nextLevel: 'level-23',
        levelNumber: 22,
    },
    "level-23" : {
        difficulty: 5,
        blocksCount: 50,
        nextLevel: 'level-24',
        levelNumber: 23,
    },
    "level-24" : {
        difficulty: 5,
        blocksCount: 100,
        nextLevel: 'level-25',
        levelNumber: 24,
    },
    "level-25" : {
        difficulty: 10,
        blocksCount: 5,
        nextLevel: 'level-26',
        levelNumber: 25,
    },
    "level-26" : {
        difficulty: 10,
        blocksCount: 10,
        nextLevel: 'level-27',
        levelNumber: 26,
    },
    "level-27" : {
        difficulty: 10,
        blocksCount: 15,
        nextLevel: 'level-28',
        levelNumber: 27,
    },
    "level-28" : {
        difficulty: 10,
        blocksCount: 20,
        nextLevel: 'level-29',
        levelNumber: 28,
    },
    "level-29" : {
        difficulty: 10,
        blocksCount: 30,
        nextLevel: 'level-30',
        levelNumber: 29,
    },
    "level-30" : {
        difficulty: 10,
        blocksCount: 40,
        nextLevel: 'level-31',
        levelNumber: 30,
    },
    "level-31" : {
        difficulty: 10,
        blocksCount: 50,
        nextLevel: 'level-32',
        levelNumber: 31,
    },
    "level-32" : {
        difficulty: 10,
        blocksCount: 100,
        nextLevel: 'level-32',
        levelNumber: 32,
    },
}