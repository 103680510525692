import React from "react";
import { useGLTF } from "@react-three/drei";

export default function EndModel(props) {
  const { nodes, materials } = useGLTF("./models/s_04.glb");

  return (
    <group
        {...props}
        dispose={null}
        scale={[0.006, 0.006, 0.006]}
        rotation={[0, 4.75, 0]}
        position={[0, -0.4, 0]}
    >
      <mesh
        name="pCylinder3"
        castShadow
        receiveShadow
        geometry={nodes.Solana_logo.geometry}
        material={materials['Mat']}
      />
    </group>
  );
}

useGLTF.preload("./models/s_04.glb");
