import {useEffect} from "react";
import useGame from "../stores/useGame.js";
import useUser from "../stores/useUser.js";
import {levelsObj} from "../stores/levels.js";
import AnswerImg from "../assets/answer.png";
import HeaderLeaderboard from "../assets/leaderboard-header.png";
import PlayButton from "../assets/play-button.png";

export default function MainMenu() {
  const { setBlocksCount, setDifficulty, setIsInGame } = useGame();
  const level = useUser((state) => state.level);
  const leaderBoard = useUser((state) => state.leaderBoard);

  useEffect(() => {
    const currentLevel = levelsObj[level];
    const difficulty = currentLevel?.difficulty || 1;
    const blocksCount = currentLevel?.blocksCount || 5;
    setDifficulty(difficulty);
    setBlocksCount(blocksCount);
  }, [level]);

  document.addEventListener("keydown", (e) => {
    if (e.code === "Enter") {
      setIsInGame(true);
    }
  });

  return (
      <div className="main-menu">
        <div className="main-menu-content">
          <div className="main-menu-answer">
            <img src={AnswerImg} alt="answer src"/>
          </div>
          <div className='main-menu-leaderboard-wrapper'>
            <div className="main-menu-leaderboard">
              <div className="main-menu-leaderboard-header">
                <img src={HeaderLeaderboard} alt="header leaderboard"/>
              </div>
              <div className="main-menu-table">
                <div className="main-menu-table-header">
                  <div className="main-menu-table-header-row">PLACE</div>
                  <div className="main-menu-table-header-row-address">ADDRESS</div>
                  <div className="main-menu-table-header-row">LVL</div>
                  <div className="main-menu-table-header-row-time">TIME min:sec</div>
                  <div className="main-menu-table-header-row">SCORE</div>
                </div>
                <div className="main-menu-table-body">
                  {leaderBoard.map((item => {
                    return (
                        <div
                            className={item.isMe ? "main-menu-table-row-wrapper-active" : "main-menu-table-row-wrapper"}
                            key={item.id}>
                          <div className="main-menu-table-row">{item.place}</div>
                          <div className="main-menu-table-row-address">{item.isMe ? 'YOU' : item.address}</div>
                          <div className="main-menu-table-row-level">{item.level}</div>
                          <div className="main-menu-table-row-time">{item.time}</div>
                          <div className="main-menu-table-row">{item.score}</div>
                        </div>
                    )
                  }))}
                </div>
              </div>
              <div className="main-menu-leaderboard-play" onClick={() => setIsInGame(true)}>
                <img src={PlayButton} alt="play leaderboard"/>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
