import React, {useEffect, useState} from 'react';
import ConfirmButton from "../assets/confirm-button.png";
import useUser from "../stores/useUser.js";
import {isValidSolanaAddress, removeLocalStorage} from "../stores/utils.js";
import MainMenu from "../interface/MainMenu.jsx";
// import './WalletForm.css';
//main-menu-leaderboard-play
const ConnectWithoutProgram = () => {
    const {
        loginWithoutProgram,
        isLogin,
        verify,
        getLeaderBoard,
        isLoading
    } = useUser((state) => state);
    const [address, setAddress] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        removeLocalStorage('walletName')
        verify();
    }, [verify])

    useEffect(() => {
        if (isLogin){
            getLeaderBoard();
        }
    }, [isLogin, getLeaderBoard])

    const onChangeHandler = (e) => {
        setAddress(e.target.value);
        setError('');
    }

    const confirm = () => {
        if (address) {
            const isValid = isValidSolanaAddress(address);
            console.log(isValid, "isValid")
            if (isValid){
                loginWithoutProgram(address);
            }else {
                setError('Please enter a valid solana address');
            }
        }else {
            setError('Please enter a solana address for continue');
        }
    }

    if (isLoading){
        return <div className="wallet-form-container">555</div>
    }

    if (isLogin) {
        return <MainMenu/>
    }

    return (
        <div className="wallet-form-container">
            <div className="wallet-form">
                <h1>Enter wallet address (solana)</h1>
                <input type="text" placeholder="HKLRcZ57DHuYJhSKRZtnNcJJeZUoUNWBUHuiWHGm28kY" onChange={onChangeHandler} value={address}/>
                {error && <div className="error-message">{error}</div>}
                <div className="wallet-form-button" onClick={() => confirm()}>
                    <img src={ConfirmButton} alt="confirm leaderboard"/>
                </div>
            </div>
        </div>
    );
};

export default ConnectWithoutProgram;