export default function Loading({type}) {
  const text = type === "loading" ? "LOADING" : "CONNECTING";
  return (
      <div className="loading">
          <div className="loading-middle">
              <div className="loading-text">{text}</div>
          </div>
      </div>
  );
}
