import { Physics } from "@react-three/rapier";
import { Perf } from "r3f-perf";
import Lights from "./Lights.jsx";
import { RandomLevel } from "./level/Level.jsx";

import Ball from "./Ball.jsx";
import useGame from "./stores/useGame.js";
import useAudio from "./stores/useAudio.js";
import { SoundManager } from "./utils/SoundManager.jsx";
import { useEffect } from "react";

export default function Experience() {
  const performance = useGame((state) => state.performance);
  const showPerformance = useGame((state) => state.showPerformance);
  const restart = useGame((state) => state.restart);
  const difficulty = useGame((state) => state.difficulty);
  const blocksCount = useGame((state) => state.blocksCount);
  const blocksSeed = useGame((state) => state.blocksSeed);
  const toggleAudio = useAudio((state) => state.toggleAudio);

  useEffect(() => {
    const handleKeyDown = (e) => {

      if (e.code === "KeyR") {
        restart();
      } else if (e.code === "KeyM") {
        toggleAudio();
      } else if (e.code === "KeyP") {
        showPerformance();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [restart, toggleAudio, showPerformance]);

  return (
    <>
      {performance && <Perf position="bottom-left" />}
      <Physics debug={false} interpolate={false}>
        <Lights/>

        <RandomLevel
            count={blocksCount}
            seed={blocksSeed}
            difficulty={difficulty}
        />
        <Ball/>
        <SoundManager />
      </Physics>
    </>
  );
}
